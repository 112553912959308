import { useRequest } from "ahooks"
import axios from "axios"

export const checkAuthToken = async () => {
  try {
    const { data } = await axios.get(`/api/check-auth-token`)
    return data
  } catch (error) {
    return false
  }
}

export const useCheckAuthTokenAuto = () => {
  const { loading, data, error } = useRequest(checkAuthToken)

  return { loading, data, error }
}

export const useCheckAuthTokenManual = () => {
  const { loading, data, error, runAsync } = useRequest(checkAuthToken, {
    manual: true,
  })

  return { loading, data, error, runAsync }
}
