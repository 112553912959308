import React from "react"
import { Route, Routes } from "react-router-dom"

import { RKFooter } from "../../components/RKFooter/RKFooter"
import { Landing } from "../../features/Landing"
import { RKHeader } from "../../features/RKHeader/RKHeader"
import { SettingPage } from "../../pages/Setting/Setting"
import { SignUpPage } from "../../pages/SignUp/SignUp"

export const AppLayout = () => {
  return (
    <>
      <RKHeader />
      <div
        style={{
          padding: "64px 30px 0 30px",
          minHeight: "calc(100vh - 144px)",
          textAlign: "center",
          maxWidth: "1000px",
          margin: "0 auto",
        }}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/setting" element={<SettingPage />} />
        </Routes>
      </div>
      <RKFooter />
    </>
  )
}
