import { Link } from "react-router-dom"

export const RKFooter = () => {
  return (
    <div
      style={{
        lineHeight: 2,
        borderTop: "1px solid #F5F5F5",
        padding: "10px 32px",
      }}>
      <span
        style={{
          color: "rgba(2, 1, 34, 0.6)",
          display: "block",
        }}>
        © 2023 Daifo Pty Ltd
      </span>
      <Link
        style={{ display: "block", color: "#3f9bca", textDecoration: "none" }}
        to={`//receiptpapa.com/privacy-policy`}
        target="_blank">
        Privacy Policy
      </Link>
      <Link
        style={{ display: "block", color: "#3f9bca", textDecoration: "none" }}
        to={`//receiptpapa.com/terms-of-use`}
        target="_blank">
        Terms of Use
      </Link>
      <Link
        style={{ display: "block", color: "#3f9bca", textDecoration: "none" }}
        to={`//receiptpapa.com/terms-of-service`}
        target="_blank">
        Terms of Service
      </Link>
    </div>
  )
}
