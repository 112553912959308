import { CopyOutlined } from "@ant-design/icons"
import { Button, message, Spin } from "antd"
import copy from "copy-to-clipboard"
import { useEffect, useState } from "react"
import useDrivePicker from "react-google-drive-picker"
import { useNavigate } from "react-router-dom"

import { useLogout } from "../../common/hook/useLogout"
import { RKSkeleton } from "../../components/RKSkeleton/RKSkeleton"
import { REACT_APP_CLIENT_ID, REACT_APP_DAIFO_EMAIL_DOMAIN, REACT_APP_DEVELOPER_KEY } from "../../constant"
import { useAuth } from "../../context/AuthContext"
import { useMixpanel } from "../../context/MixpanelContext"
import { useCheckAuthTokenManual } from "../../hooks/checkGoogleApiToken"
import googleDriveLogo from "../../resources/images/google-drive-logo.svg"
import illustration2b from "../../resources/images/illustration2b.svg"
import illustration3b from "../../resources/images/illustration3b.svg"
import successIcon from "../../resources/images/success.svg"
import { useGetMe, useRevoke, useUpdateUserTargetFolder } from "./hook"

export const Setting = () => {
  const { data: userData, loading: getUserDataLoading, error: getMeError } = useGetMe()
  const { runAsync: checkGoogleApiToken } = useCheckAuthTokenManual()
  const { runAsync: revoke, loading: revokeLoading } = useRevoke()
  const {
    trackRPUserSignUpCompletedEvent,
    trackRPUIFolderSelectCompletedEvent,
    trackRPUIEmailCopyCompletedEvent,
    trackRPUIRevokeClickCompletedEvent,
    trackRPUIHomePageVisitCompletedEvent,
    mixpanelAlias,
  } = useMixpanel()
  const { setUserInfo } = useAuth()
  const {
    runAsync: updateUserTargetFolder,
    data: newUserTargetFolderData,
    loading: updateUserTargetFolderLoading,
  } = useUpdateUserTargetFolder()
  const [openPicker] = useDrivePicker()
  const navigate = useNavigate()
  const { logout } = useLogout()
  const [targetFolderName, setTargetFolderName] = useState("Select In Google Drive")
  const [userEmail, setUserEmail] = useState("")

  useEffect(() => {
    if (getMeError) {
      if (/401/.test(getMeError.message)) {
        navigate(`/signup`)
      }
    }
  }, [getMeError, navigate])

  useEffect(() => {
    if (newUserTargetFolderData) {
      console.info(newUserTargetFolderData.GoogleDrive.targetFolder.name)
      setTargetFolderName(newUserTargetFolderData.GoogleDrive.targetFolder.name)
    }
  }, [newUserTargetFolderData])

  useEffect(() => {
    if (userData?.GoogleDrive.targetFolder?.name) {
      setTargetFolderName(userData.GoogleDrive.targetFolder.name)
    }
  }, [userData])

  useEffect(() => {
    trackRPUIHomePageVisitCompletedEvent()
  }, [trackRPUIHomePageVisitCompletedEvent])

  useEffect(() => {
    if (userData) {
      setUserInfo({
        username: userData.username,
        email: userData.email,
      })
      mixpanelAlias(userData.id)
      trackRPUserSignUpCompletedEvent({ distinct_id: userData.id })
      setUserEmail(`${userData.username}@${REACT_APP_DAIFO_EMAIL_DOMAIN}`)
    }
  }, [mixpanelAlias, setUserInfo, trackRPUserSignUpCompletedEvent, userData])

  const handleOpenPicker = async () => {
    const isTokenValidated = await checkGoogleApiToken()
    if (!isTokenValidated) {
      navigate(`/signup`)
      return
    } else {
      if (userData) {
        openPicker({
          clientId: REACT_APP_CLIENT_ID,
          developerKey: REACT_APP_DEVELOPER_KEY,
          viewId: "FOLDERS",
          token: userData.GoogleDrive.auth.accessToken,
          multiselect: false,
          setSelectFolderEnabled: true,
          callbackFunction: async (data) => {
            if (data.action === "cancel") {
              console.log("User clicked cancel/close button")
            }
            if (data.docs[0].type !== "folder") {
              message.error("you need to select a folder")
            }
            if (userData.username) {
              const { id, name } = data.docs[0]
              await updateUserTargetFolder({
                id,
                name,
              })
              message.success("Folder successfully set!")
            }
            trackRPUIFolderSelectCompletedEvent({ distinct_id: userData.id })
          },
        })
      }
    }
  }

  const handleRevoke = async () => {
    if (userData) {
      await revoke(userData.username)
      await logout()
      setUserInfo(undefined)
      trackRPUIRevokeClickCompletedEvent({ distinct_id: userData.id })
      navigate(`/signup`)
    }
  }

  const copyEmail = () => {
    copy(userEmail)
    message.success("email has been copied!")
    if (userData) {
      trackRPUIEmailCopyCompletedEvent({ distinct_id: userData.id })
    }
  }

  return (
    <>
      {getUserDataLoading ? (
        <RKSkeleton />
      ) : (
        <div>
          {updateUserTargetFolderLoading ? (
            <span style={{ position: "fixed", left: "50%", top: "50%" }}>
              <Spin size="large" />
            </span>
          ) : null}
          <img src={successIcon} alt="success icon" style={{ width: "60px", margin: "45px auto 0 auto" }} />
          <h2>
            Step 01 <br />
            Connected Successfully!
          </h2>
          <img src={illustration2b} alt="illustration2b" style={{ margin: "45px auto 0 auto" }} />
          <div style={{ margin: "40px auto 60px auto" }}>
            <h2>
              Step 02 <br />
              Forward Receipts
            </h2>
            <p>
              Send your PDF receipts to the following address. ReceiptPapa will automatically organise them by category
              & date.
            </p>
            <div
              style={{
                background: "rgba(255, 82, 27, 0.04)",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
              }}>
              <span style={{ fontWeight: "bolder" }}>{userEmail}</span>
              <CopyOutlined style={{ color: "#FF521B", fontSize: "18px" }} onClick={copyEmail} />
            </div>
          </div>
          <div style={{ margin: "60px auto" }}>
            <img src={illustration3b} alt="illustration3b" style={{ margin: "45px auto 0 auto" }} />
            <h2>
              Step 03 <br />
              Check Your Receipts
            </h2>
            <p>
              All your receipts are synced to <strong>ReceiptPapa</strong> folder in your Google Drive by default. You
              can change the folder below.
            </p>
            <Button
              style={{
                border: "none",
                color: "#0000008A",
                boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24)",
                borderRadius: "2px",
                paddingLeft: "8px",
                paddingRight: "8px",
                backgroundColor: "#fff",
              }}
              onClick={() => handleOpenPicker()}>
              <img
                style={{
                  marginRight: "14px",
                }}
                src={googleDriveLogo}
                alt="google"
              />{" "}
              {targetFolderName}
            </Button>

            {userData ? (
              <Button
                type="primary"
                ghost
                onClick={() => handleRevoke()}
                style={{ margin: "10px auto 0 auto", display: "block" }}
                loading={revokeLoading}>
                revoke
              </Button>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}
