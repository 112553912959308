import mixpanel from "mixpanel-browser"
import { createContext, useContext } from "react"

import { MIXPANEL_TOKEN } from "../../constant"
import { MixpanelContextType, MixpanelTrackingEvent } from "./MixpanelContext.type"

export const MixpanelContext = createContext<MixpanelContextType | undefined>(undefined)

MixpanelContext.displayName = "MixpanelContext"

mixpanel.init(MIXPANEL_TOKEN, {
  ignore_dnt: true,
})

export const MixpanelProvider = ({ children }: { children: React.ReactNode }) => {
  const trackRPUserSignUpCompletedEvent = (data?: { distinct_id: string; [propName: string]: unknown }) => {
    mixpanel.track(MixpanelTrackingEvent.RP_USER_SIGNUP_COMPLETED, data)
  }

  const trackRPUIEmailCopyCompletedEvent = (data?: { distinct_id: string; [propName: string]: unknown }) => {
    mixpanel.track(MixpanelTrackingEvent.RP_UI_EMAIL_COPY_COMPLETED, data)
  }

  const trackRPUIFolderSelectCompletedEvent = (data?: { distinct_id: string; [propName: string]: unknown }) => {
    mixpanel.track(MixpanelTrackingEvent.RP_UI_FOLDER_SELECT_COMPLETED, data)
  }

  const trackRPUIRevokeClickCompletedEvent = (data?: { distinct_id: string; [propName: string]: unknown }) => {
    mixpanel.track(MixpanelTrackingEvent.RP_UI_REVOKE_CLICK_COMPLETED, data)
  }

  const trackRPUIHomePageVisitCompletedEvent = (data?: { distinct_id: string; [propName: string]: unknown }) => {
    mixpanel.track(MixpanelTrackingEvent.RP_UI_HOME_PAGE_VISIT_COMPLETED, data)
  }

  const trackRPUILandingPageVisitCompletedEvent = (data?: { distinct_id: string; [propName: string]: unknown }) => {
    mixpanel.track(MixpanelTrackingEvent.RP_UI_LANDING_PAGE_VISIT_COMPLETED, data)
  }

  const trackRPUISignInPageVisitCompletedEvent = (data?: { distinct_id: string; [propName: string]: unknown }) => {
    mixpanel.track(MixpanelTrackingEvent.RP_UI_SIGNIN_PAGE_VISIT_COMPLETED, data)
  }

  const mixpanelAlias = (alias: string) => {
    mixpanel.alias(alias)
  }

  return (
    <MixpanelContext.Provider
      value={{
        trackRPUserSignUpCompletedEvent,
        trackRPUIEmailCopyCompletedEvent,
        trackRPUIFolderSelectCompletedEvent,
        trackRPUIRevokeClickCompletedEvent,
        trackRPUIHomePageVisitCompletedEvent,
        trackRPUILandingPageVisitCompletedEvent,
        trackRPUISignInPageVisitCompletedEvent,
        mixpanelAlias,
      }}>
      {children}
    </MixpanelContext.Provider>
  )
}

export const useMixpanel = () => {
  const context = useContext(MixpanelContext)
  if (!context) {
    throw new Error(`${MIXPANEL_TOKEN}----Mixpanel context has not been initiated`)
  }

  return context
}
