import "antd/dist/antd.less"

import React from "react"

import { AuthProvider } from "./context/AuthContext"
import { MixpanelProvider } from "./context/MixpanelContext"
import { AppLayout } from "./layout/AppLayout/AppLayout"

export const App = () => {
  return (
    <AuthProvider>
      <MixpanelProvider>
        <AppLayout />
      </MixpanelProvider>
    </AuthProvider>
  )
}
