import { Skeleton } from "antd"

export const RKSkeleton = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  )
}
