import { useRequest } from "ahooks"
import axios from "axios"

export const logoutRequest = async () => {
  const { data } = await axios.get(`/api/logout`)
  return data
}

export const useLogout = () => {
  const {
    data,
    error,
    loading,
    runAsync: logout,
  } = useRequest(logoutRequest, {
    manual: true,
  })

  return { loading, data, error, logout }
}
