import { createContext, useContext, useState } from "react"

import { AuthContextType, UserInfo } from "./AuthContext.type"

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

AuthContext.displayName = "AuthContext"

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        setUserInfo,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`Auth context has not been initiated`)
  }

  return context
}
