import { useNavigate } from "react-router-dom"

import { useLogout } from "../../common/hook/useLogout"
import { useAuth } from "../../context/AuthContext"
import logo from "../../resources/images/logo.svg"
import logoutIcon from "../../resources/images/logout.svg"

export const RKHeader = () => {
  const { setUserInfo, userInfo } = useAuth()
  const { logout } = useLogout()
  const navigate = useNavigate()

  const handleLogout = async () => {
    await logout()
    setUserInfo(undefined)
    navigate(`/signup`)
  }

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        background: "#fff",
        zIndex: 10,
        boxShadow:
          "0px 3px 5px -1px rgba(190, 190, 190, 0.2), 0px 6px 10px rgba(190, 190, 190, 0.14), 0px 1px 18px rgba(190, 190, 190, 0.12)",
      }}>
      <img src={logo} alt="ReceiptPapa logo" style={{ height: "32px", margin: "16px 16px" }} />
      {userInfo ? (
        <div
          style={{
            width: "64px",
            height: "64px",
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleLogout}>
          <img src={logoutIcon} alt="logout" style={{ height: "20px" }} />
        </div>
      ) : null}
    </div>
  )
}
