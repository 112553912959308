import { ArrowRightOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { useEffect } from "react"

import { useMixpanel } from "../../context/MixpanelContext"
import illustration1 from "../../resources/images/illustration1.svg"
import illustration2 from "../../resources/images/illustration2.svg"
import illustration3 from "../../resources/images/illustration3.svg"
import illustration4 from "../../resources/images/illustration4.svg"

export const Landing = () => {
  const { trackRPUILandingPageVisitCompletedEvent } = useMixpanel()

  useEffect(() => {
    trackRPUILandingPageVisitCompletedEvent()
  }, [trackRPUILandingPageVisitCompletedEvent])

  return (
    <div
      style={{
        textAlign: "center",
      }}>
      <img src={illustration1} alt="illustration1" style={{ margin: "45px auto" }} />
      <h2>Receipt management tool to save you time</h2>
      <p>
        Daifo ReceiptPapa uses Artificial Intelligence to automatically extract your receipts from your emails and then
        organise them into different categorised folders in your Google Drive. No need to stress about doing it yourself
        anymore.
      </p>
      <Button type="primary" href={"/signup"} style={{ margin: "40px auto 70px auto" }}>
        START FOR FREE
        <ArrowRightOutlined />
      </Button>

      <h2>How does Daifo ReceiptPapa work？</h2>
      <img src={illustration2} alt="illustration2" style={{ margin: "45px auto" }} />
      <h3>01 Create an Account</h3>
      <p>
        Sign in with your Google account and allow Google Drive access for Daifo ReceiptPapa. This enable us to create
        folders in your Google Drive and neatly organise your receipts into the correct folders.
      </p>

      <img src={illustration3} alt="illustration3" style={{ margin: "45px auto" }} />
      <h3>02 Gather Your Receipts</h3>
      <p>
        Forward your receipts to your Daifo ReceiptPapa email address. It will then intelligently analyse and extract
        receipts from your emails.
      </p>

      <img src={illustration4} alt="illustration4" style={{ margin: "45px auto" }} />
      <h3>03 Keep Receipts Securely</h3>
      <p>
        Daifo ReceiptPapa automatically organise the extracted receipts by category & date and store them in your chosen
        Google Drive folder where you can view or download at any time.
      </p>
      <Button type="primary" href={"/signup"} style={{ margin: "40px auto 70px auto" }}>
        SIGN UP NOW
        <ArrowRightOutlined />
      </Button>
    </div>
  )
}
