import { useRequest } from "ahooks"
import axios from "axios"

import { IUser } from "../../common/type"

export const getMe = async () => {
  const { data } = await axios.post<IUser>(`/api/view-user`)
  return data
}

export const useGetMe = () => {
  const { data, error, loading } = useRequest(getMe)

  return { loading, data, error }
}

export const updateUserTargetFolder = async (targetFolder: { id: string; name: string }) => {
  const { data } = await axios.post(`/api/update-user-target-folder`, {
    targetFolder,
  })
  return data
}

export const useUpdateUserTargetFolder = () => {
  const { data, error, loading, runAsync } = useRequest(updateUserTargetFolder, {
    manual: true,
  })

  return { loading, runAsync, data, error }
}

export const revoke = async (username: string) => {
  const { data } = await axios.post(`/api/revoke`, {
    username,
  })
  return data
}

export const useRevoke = () => {
  const { data, error, loading, runAsync } = useRequest(revoke, {
    manual: true,
  })

  return { loading, data, error, runAsync }
}
