export type MixpanelContextType = {
  trackRPUserSignUpCompletedEvent: (data?: { distinct_id: string; [propName: string]: unknown }) => void
  trackRPUIEmailCopyCompletedEvent: (data?: { distinct_id: string; [propName: string]: unknown }) => void
  trackRPUIFolderSelectCompletedEvent: (data?: { distinct_id: string; [propName: string]: unknown }) => void
  trackRPUIRevokeClickCompletedEvent: (data?: { distinct_id: string; [propName: string]: unknown }) => void
  trackRPUIHomePageVisitCompletedEvent: (data?: { distinct_id: string; [propName: string]: unknown }) => void
  trackRPUILandingPageVisitCompletedEvent: (data?: { distinct_id: string; [propName: string]: unknown }) => void
  trackRPUISignInPageVisitCompletedEvent: (data?: { distinct_id: string; [propName: string]: unknown }) => void

  mixpanelAlias: (alias: string) => void
}

export enum MixpanelTrackingEvent {
  RP_USER_SIGNUP_COMPLETED = "RP_USER_SIGNUP_COMPLETED",
  RP_UI_EMAIL_COPY_COMPLETED = "RP_UI_EMAIL_COPY_COMPLETED",
  RP_UI_FOLDER_SELECT_COMPLETED = "RP_UI_FOLDER_SELECT_COMPLETED",
  RP_UI_REVOKE_CLICK_COMPLETED = "RP_UI_REVOKE_CLICK_COMPLETED",
  RP_UI_HOME_PAGE_VISIT_COMPLETED = "RP_UI_HOME_PAGE_VISIT_COMPLETED",
  RP_UI_LANDING_PAGE_VISIT_COMPLETED = "RP_UI_LANDING_PAGE_VISIT_COMPLETED",
  RP_UI_SIGNIN_PAGE_VISIT_COMPLETED = "RP_UI_SIGNIN_PAGE_VISIT_COMPLETED",
}
