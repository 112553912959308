import { Button } from "antd"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import { RKSkeleton } from "../../components/RKSkeleton/RKSkeleton"
import { useMixpanel } from "../../context/MixpanelContext"
import { useCheckAuthTokenAuto } from "../../hooks/checkGoogleApiToken"
import googleDriveLogo from "../../resources/images/google-drive-logo.svg"
import illustrationSignup from "../../resources/images/illustration-signup.svg"
import { useGetGoogleAuthUrl } from "./hook"

export const SignUp = () => {
  const navigate = useNavigate()
  const { data: checkAuthTokenData, loading: checkAuthTokenLoading } = useCheckAuthTokenAuto()
  const { loading, data: url, run: getGoogleAuthUrl } = useGetGoogleAuthUrl()
  const { trackRPUISignInPageVisitCompletedEvent } = useMixpanel()
  useEffect(() => {
    if (checkAuthTokenData === false) {
      getGoogleAuthUrl()
    } else if (checkAuthTokenData === true) {
      navigate(`/setting`)
    }
  }, [checkAuthTokenData, getGoogleAuthUrl, navigate])

  useEffect(() => {
    trackRPUISignInPageVisitCompletedEvent()
  }, [trackRPUISignInPageVisitCompletedEvent])
  return (
    <>
      {checkAuthTokenLoading ? (
        <RKSkeleton />
      ) : (
        <div
          style={{
            textAlign: "center",
          }}>
          <img src={illustrationSignup} alt="illustration" style={{ margin: "45px auto" }} />
          <h2>Step 01 Sign In</h2>
          <p>Quickly get started by signing in using your existing Google account.</p>
          <Button
            ghost
            loading={loading}
            href={url}
            style={{
              border: "none",
              margin: "40px auto 70px auto",
              color: "#0000008A",
              boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24)",
              borderRadius: "2px",
              paddingLeft: "8px",
              paddingRight: "8px",
              backgroundColor: "#fff",
            }}>
            <img
              style={{
                marginRight: "14px",
              }}
              src={googleDriveLogo}
              alt="google"
            />{" "}
            Sign In With Google Drive
          </Button>
          <p>
            By proceeding, you are agreeing to{" "}
            <Link
              style={{ color: "#3f9bca", textDecoration: "none" }}
              to={`//receiptpapa.com/terms-of-use`}
              target="_blank">
              Daifo’s Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              style={{ color: "#3f9bca", textDecoration: "none" }}
              to={`//receiptpapa.com/privacy-policy`}
              target="_blank">
              Privacy Policy
            </Link>{" "}
          </p>
        </div>
      )}
    </>
  )
}
