import { useRequest } from "ahooks"
import axios from "axios"

export const getGoogleAuthUrl = async () => {
  const { data } = await axios.get(`/api/get-auth-url`)
  return data
}

export const useGetGoogleAuthUrl = () => {
  const { loading, data, error, run } = useRequest(getGoogleAuthUrl, {
    manual: true,
  })

  return { loading, data, error, run }
}
